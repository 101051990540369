import React from 'react'
import { Link } from 'gatsby'
import Image from 'gatsby-image'
import { useIntl } from "gatsby-plugin-intl"
import { useStaticQuery, graphql } from "gatsby"

const BlogSidebarVi = () => {
  const intl = useIntl()
  const { allMarkdownRemark } = useStaticQuery(
    graphql`
      query {
        allMarkdownRemark(
          filter: {frontmatter: {type: {eq: "blog"}}}
          sort: {fields: [frontmatter___date, frontmatter___language], order: [DESC, DESC]}
          limit: 3
        ) {
          edges {
            node {
              id
              frontmatter {
                title
                slug
                date
                cover {
                  childImageSharp {
                      fluid(maxWidth: 750, maxHeight: 650, quality: 100) {
                        ...GatsbyImageSharpFluid_noBase64
                      }
                  }
                }
              }
            }
          }
        }
      }
    `
  )
  const posts = allMarkdownRemark.edges
  return (
    <div className="widget-area">
      <div className="widget widget_search">
        <h3 className="widget-title">{intl.formatMessage({ id: "search.Title" })}</h3>

        <form className="search-form">
          <label>
            <span className="screen-reader-text">{intl.formatMessage({ id: "search.SearchFor" })}</span>
            <input type="search" className="search-field" placeholder={intl.formatMessage({ id: "search.Placeholder" })} />
          </label>
          <button type="submit">
            <i className="bx bx-search-alt"></i>
          </button>
        </form>
      </div>

      <div className="widget widget_tracer_posts_thumb">
        <h3 className="widget-title">{intl.formatMessage({ id: "popularPosts" })}</h3>
          {posts.map(post => {
            const date = new Date(post.node.frontmatter.date);
            const dateFormatted = new Intl.DateTimeFormat(
              'vi-VN', { year: 'numeric', month: 'long', day: '2-digit' }
            ).format(date)
            return (
              <article key={post.id} className="item">
                <span className="fullimage cover" role="img">
                  <Link to={`/${intl.locale}/${post.node.frontmatter.slug}`} className="thumb">
                    <Image fluid={post.node.frontmatter.cover.childImageSharp.fluid} alt="thumbnail" />
                  </Link>
                </span>

                <div className="info">
                  <span>{dateFormatted}</span>
                  <h4 className="title usmall">
                    <Link to={`/${intl.locale}/${post.node.frontmatter.slug}`}>
                      {post.node.frontmatter.title}
                    </Link>
                  </h4>
                </div>

                <div className="clear"></div>
              </article>
            )
          })}
      </div>

      <div className="widget widget_categories">
        <h3 className="widget-title">{intl.formatMessage({ id: "categories" })}</h3>

        <ul>
          <li>
            <Link to="#">
              Design <span className="post-count">(03)</span>
            </Link>
          </li>
          <li>
            <Link to="#">
              Lifestyle <span className="post-count">(05)</span>
            </Link>
          </li>
          <li>
            <Link to="#">
              Script <span className="post-count">(10)</span>
            </Link>
          </li>
          <li>
            <Link to="#">
              Device <span className="post-count">(08)</span>
            </Link>
          </li>
          <li>
            <Link to="#">
              Tips <span className="post-count">(01)</span>
            </Link>
          </li>
        </ul>
      </div>

      <div className="widget widget_tag_cloud">
        <h3 className="widget-title">{intl.formatMessage({ id: "popularTags" })}</h3>

        <div className="tagcloud">
          <Link to="#">
            Business <span className="tag-link-count">(3)</span>
          </Link>
          <Link to="#">
            Design <span className="tag-link-count">(3)</span>
          </Link>
          <Link to="#">
            Braike <span className="tag-link-count">(2)</span>
          </Link>
          <Link to="#">
            Fashion <span className="tag-link-count">(2)</span>
          </Link>
          <Link to="#">
            Travel <span className="tag-link-count">(1)</span>
          </Link>
          <Link to="#">
            Smart <span className="tag-link-count">(1)</span>
          </Link>
          <Link to="#">
            Marketing <span className="tag-link-count">(1)</span>
          </Link>
          <Link to="#">
            Tips <span className="tag-link-count">(2)</span>
          </Link>
        </div>
      </div>
    </div>
  )
}

export default BlogSidebarVi