import React from 'react'
import Layout from "../components/App/Layout"
import NavbarTwo from "../components/App/NavbarTwo"
import PageBanner from '../components/Common/PageBanner'
import Footer from "../components/App/Footer"
import BlogDetailsContent from '../components/BlogContent/BlogDetailsContent'
import { graphql } from 'gatsby'
import { useIntl } from "gatsby-plugin-intl"

const BlogDetails = (props) => {
  const postData = props.data.markdownRemark
  const intl = useIntl()
  let homePageUrl = '/en/'
  if (intl.locale == 'vi') {
    homePageUrl = '/vi/'
  }
  const title = postData.frontmatter.title
  return (
    <Layout>
      <NavbarTwo bgWhite={false} />
      <PageBanner
        pageTitle={title}
        homePageText={intl.formatMessage({ id: "home" })}
        homePageUrl={homePageUrl}
        activePageText={title}
      />
      <BlogDetailsContent data={postData} />
      <Footer />
    </Layout>
  )
}

export default BlogDetails;

export const pageQuery = graphql`
  query BlogPostBySlug($slug: String!) {
      markdownRemark(fields: { slug: { eq: $slug } }) {
        id
        excerpt(pruneLength: 160)
        html
        frontmatter {
          type
          language
          title
          slug
          date
          cover {
            childImageSharp {
              fluid(maxWidth: 750, maxHeight: 650, quality: 100) {
              ...GatsbyImageSharpFluid_noBase64
              }
            }
          }
          tags
          author
          aavatar {
            publicURL
            childImageSharp {
              fluid(maxWidth: 100, jpegQuality: 100) {
                ...GatsbyImageSharpFluid_noBase64
            }
          }
        }
      }
    }
  }
`;