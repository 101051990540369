import React from 'react'
import {Link} from 'gatsby'
import BlogSidebarVi from './BlogSidebarVi'
import BlogSidebarEn from './BlogSidebarEn'
import Image from 'gatsby-image'
import { useIntl } from "gatsby-plugin-intl"

const BlogDetailsContent = ({ data }) => {
    const intl = useIntl()
    return (
        <section className="blog-details-area ptb-100">
            <div className="container">
                <div className="row">
                    <div className="col-lg-8 col-md-12">
                        <div className="blog-details-desc">
                            <div className="article-image">
                                <Image fluid={data.frontmatter.cover.childImageSharp.fluid} alt="bb" />
                            </div>

                            <div className="article-content">
                                <div dangerouslySetInnerHTML={{ __html: data.html }} />
                            </div>

                            <div className="article-footer">
                                <div className="article-tags">
                                    <span><i className='bx bx-purchase-tag'></i></span>
                                    {data.frontmatter.tags.map((tag, index) => <Link key={index} to="#">{tag}</Link>)}
                                </div>
                                {/* <div className="article-share">
                                    <ul className="social">
                                        <li><span>Share:</span></li>
                                        <li>
                                            <Link to="#" className="facebook">
                                                <i className='bx bxl-facebook'></i>
                                            </Link>
                                        </li>
                                        <li>
                                            <Link to="#" className="twitter">
                                                <i className='bx bxl-twitter'></i>
                                            </Link>
                                        </li>
                                        <li>
                                            <Link to="#" className="linkedin">
                                                <i className='bx bxl-linkedin'></i>
                                            </Link>
                                        </li>
                                        <li>
                                            <Link to="#" className="instagram">
                                                <i className='bx bxl-instagram'></i>
                                            </Link>
                                        </li>
                                    </ul>
                                </div> */}
                            </div>

                            <div className="article-author">
                                <div className="author-profile-header"></div>
                                <div className="author-profile">
                                    <div className="author-profile-title">
                                        <img src={data.frontmatter.aavatar.publicURL} className="shadow-sm" alt="uu" />
                                        <h4>{data.frontmatter.author}</h4>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="col-lg-4 col-md-12">
                        {intl.locale == 'vi' ? <BlogSidebarVi /> : <BlogSidebarEn />}
                    </div>
                </div>
            </div>
        </section>
    )
}

export default BlogDetailsContent